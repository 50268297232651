<script>
  import Lazy from "svelte-lazy";
</script>

<div class="bg-[#FFEFEE] w-full flex justify-between mt-16 lg:mt-4 py-8"
  style="border-radius: 16px;">
  <div class="flex flex-col justify-between w-full lg:flex-row px-12">
    <h1
      class="font-[600] text-[20px] md:text-[24px] lg:text-[30px] mb-5 md:mb-0 lg:text-start text-primary w-full lg:w-[30%] text-center">
      Studi Kasus
    </h1>
    <div class="flex flex-col w-full mt-4 lg:mt-0">
      <div class="gap-12 md:gap-4 xl:gap-12 flex flex-wrap md:flex-row justify-center lg:justify-end xl:justify-end items-center px-0 w-full my-auto">
        <Lazy height={300}>
          <a href="https://vhiweb.com/project/unesco" target="_blank">
            <img
              class="max-w-[100px] h-auto mx-auto md:mr-[1rem]"
              loading="eager"
              width="120px"
              height="50px"
              src="unesco.png"
              alt="casestudy- 3"
            />
          </a>
        </Lazy>
        <Lazy height={300}>
          <a href="https://vhiweb.com/project/perpesi" target="_blank">
            <picture>
              <img
                sizes="(max-width: 700px) 100vw, 700px"
                class="max-w-[100px] h-auto mx-auto md:mr-[1rem]"
                loading="eager"
                width="120px"
                height="50px"
                alt="casestudy- 2"
                srcset="
                  perpesi_nri0dz_c_scale,w_200.webp 200w,
                  perpesi_nri0dz_c_scale,w_700.webp 700w"
                src="perpesi_nri0dz_c_scale,w_700.webp"
              />
            </picture>
          </a>
        </Lazy>
        <Lazy height={300}>
          <a href="https://katetigama.munio.id/" target="_blank">
            <img
              class="max-w-[100px] h-auto mx-auto md:mr-[1rem]"
              loading="eager"
              width="120px"
              height="50px"
              src="katetigama.png"
              alt="casestudy- 4"
            />
          </a>
        </Lazy>
        <Lazy height={300}>
          <a href="https://vhiweb.com/project/jokowi-app" target="_blank">
            <picture>
              <img
                sizes="(max-width: 653px) 100vw, 653px"
                srcset="
                  jokowiapp_qb61of_c_scale,w_200.webp 200w,
                  jokowiapp_qb61of_c_scale,w_653.webp 653w"
                src="jokowiapp_qb61of_c_scale,w_653.webp"
                class="max-w-[100px] h-auto mx-auto"
                loading="eager"
                width="120px"
                height="50px"
                alt="casestudy- 1"
              />
            </picture>
          </a>
        </Lazy>
      </div>
    </div>
  </div>
</div>
