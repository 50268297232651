<script>
  import Lazy from "svelte-lazy";
</script>

<div class="hero min-h-screen bg-[#FFEFEE] mt-8">
  <section class="flex flex-col items-center">
    <p class="text-center text-orange-800 text-base font-semibold font-['Inter'] capitalize leading-snug">Integrate as You Want</p>
    <div class="hero-content text-center text-neutral-content">
      <div class="max-w-[900px]">
        <p
          class="font-[Courgette] text-[#101828] text-[24px] lg:text-[48px] text-center font-[400] leading-normal lg:leading-[60px] tracking-[2%] mb-5">
          <span>
            Munio 
          </span>is an online 
          <span>
            platform
          </span> 
          to 
          <span>
            help communities
          </span> managing their 
          <span>
            members
          </span>
          with engaging 
          <span>
            features.
          </span>
        </p>
      </div>
    </div>
  </section>
</div>

<style>
  .min-h-screen {
    min-height: 60vh;
  }
  p {
    text-align: center;
    color: #993732;
  }
  span {
    color: #FF5C54;
  }
  /* .items-center {
    text-align: center; */
    /* padding-top: 5rem; */
  /* } */
</style>
